import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    isLoggedIn: false,
    email: '',
    firstName: '', 
    lastName: '', 
    phone: '',
    city: '',
    county: '',
    CNP: '',
    id: null,
    error: null,
    status: 'idle',
    hasProfile: false,
    showCaptcha: false,
    loanProfile: false,
    isSubscribed: null,
    verifiedEmail: null,
    bcAuth: null,
};

const logoutStatus  = {...initialState, status: 'logout'};

export const registerUser = createAsyncThunk( 'user/register', async(user) => {
    const response = await axios({
        method: "post",
        data: user,
        url: process.env.NEXT_PUBLIC_API+'/api/register',
        headers: {'Content-Type': 'application/json'},
    });
    return response.data;
});

export const makeLogin = createAsyncThunk('user/makeLogin', async ( credentials ) => {
    const response = await axios({
        method: "post",
        data: credentials,
        url: process.env.NEXT_PUBLIC_API+'/api/account/login',
        headers: {'Content-Type': 'application/json'},
        withCredentials: true
    });
    return response.data;
  });

export const fetchProfile = createAsyncThunk( 'user/fetchProfile', async() => {
    const response = await axios({
        method: "get",
        url: process.env.NEXT_PUBLIC_API+'/api/account/profile',
        headers: {  'Content-Type': 'application/json'},
        withCredentials: true
    });
    return response.data;
})

export const doLogout = createAsyncThunk( 'user/doLogout', async() => {
    const response = await axios({
        method: "get",
        url: process.env.NEXT_PUBLIC_API+'/api/account/logout',
        headers: {'Content-Type': 'application/json'},
        withCredentials: true
    });
    return response.data;
});

export const saveProfileReview = createAsyncThunk( 'user/saveProfileReview', async(profile) => {
    const response = await axios({
        method: 'post',
        data: profile,
        url: process.env.NEXT_PUBLIC_API + '/api/user/review/profile',
        headers: {'Content-Type': 'application/json'},
        withCredentials: true,
    });
    return response.data;
});


export const getSubscriptionStatus = createAsyncThunk('user/getSubscriptionStatus', async () => {
    const response = await axios({
        method: 'get',
        url: process.env.NEXT_PUBLIC_API + '/api/user/newsletter/status',
        withCredentials: true,
        headers: {'Content-Type': 'application/json'}, 
    });
    return response.data;
});

export const subscribe = createAsyncThunk('user/subscribe', async (data) => {
    let url = `${process.env.NEXT_PUBLIC_API}/api/user/newsletter/${data.type=='subscribe' ? 'subscribe' : 'unsubscribe'}`;
    
    const response = await axios({
        method: 'post',
        data: {email: data.email},
        url: url,
        withCredentials: true,
        headers: {'Content-Type': 'application/json'}, 
    });
    return response.data;
});

export const updateProfileByRCA = createAsyncThunk('user/updateProfileByRCA', async(data)=>{
    const response = await axios({
        method: 'post',
        url: process.env.NEXT_PUBLIC_API + '/api/user/profile',
        data: data,
        withCredentials: true,
        headers: {'Content-Type': 'application/json'},
    });
    return {data: response.data, user: data};
})


const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
       connectionError : (state, action) => {
           state.error = action.payload;
           state.status = 'failed';
       },
       reset : state => logoutStatus,
       updateLoanProfile : (state, action) => {
           state.loanProfile = action.payload;
       },
       update: (state, action) => {
           for (const pair of Object.entries(action.payload)) {
                state[pair[0]] = pair[1];
           }
       }
    },
    extraReducers: {
        [makeLogin.pending] :  (state, action) => {
            state.status = "loading";
        },
        [makeLogin.fulfilled] : (state, action) => {
            const { data, status, error} = action.payload;
            if (status == true) {
                state.isLoggedIn = true;
                // state.id = data.id;
                state.status = "loggedin";
                state.error = null;
                state.showCaptcha = false;
            } else {
                const {captcha} = action.payload;
                state.error = error;
                state.status = "failed";
                state.showCaptcha = captcha;
            }
        },
        [makeLogin.rejected] : (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        [fetchProfile.fulfilled] : (state, action) => {

            const {status} = action.payload;

            if (status == true) {
                const {id, firstName, lastName, email, phone, city, county, CNP, loanProfile, verifiedEmail, bcAuth} = action.payload.data;
                state.id = id;
                state.firstName = firstName;
                state.lastName = lastName;
                state.email = email;
                state.phone = phone,
                state.CNP = CNP,
                state.city = city,
                state.county = county,
                state.isLoggedIn = true;
                state.hasProfile = true;
                state.status = 'succeeded';
                state.loanProfile = loanProfile;
                state.verifiedEmail = verifiedEmail;
                state.bcAuth = bcAuth;
            } else {
                state.isLoggedIn = false;
                state.hasProfile = false;
                state.status = "failed";
            }
        },
        [fetchProfile.pending] : (state, action) => {
            state.status = "loading";
        },
        [doLogout.pending] : (state, action) => {
            state.status = "checking";
        },
        [doLogout.fulfilled] : (state, action) => {     
            const {status} = action.payload;
            if (status == true) {
                state.status = 'logout';
                state.isLoggedIn = false;
            } 
        },

        [doLogout.rejected] : (state, action) => {
           state = initialState;
        },
        [registerUser.pending] : (state, action) => {
            state.status = "loading";
        },
        [registerUser.fulfilled]: (state, action) => {
            
            const {status} = action.payload;
         
            if (status==true) {
                state.status = 'succeeded';
                state.error = null;
            } else {
                state.status = "failed";
                state.error = action.payload.error;
            }
        },
        [registerUser.rejected] : (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        [saveProfileReview.pending] : (state, action) => {
            state.status = "loading";
        },
        [saveProfileReview.rejected] : (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        [saveProfileReview.fulfilled] : (state, action) => {
            const {status, firstName, lastName, error} = action.payload;
            if (status) {
                state.firstName = firstName;
                state.lastName = lastName;
                state.error = false;
            } else {
                state.error = error;
            }
        },
        [getSubscriptionStatus.rejected] : (state, action) => {
            state.isSubscribed = 'error';
        },
        [getSubscriptionStatus.fulfilled] : (state, action) => {
            const {status, subscriber_status} = action.payload;
            if (status) {
                state.isSubscribed = subscriber_status;
            } else {
                state.isSubscribed = 'error';
            }
        },
        [subscribe.rejected] : (state, action) => {
            state.isSubscribed = 'error';
        },
        [subscribe.fulfilled] : (state, action) => {
            const {status, subscriber_status} = action.payload;
            if (status) {
                state.isSubscribed = subscriber_status;
            } else {
                state.isSubscribed = 'error';
            }
        },
        [updateProfileByRCA.fulfilled] : (state, action) =>{
            const {data, user} = action.payload;
            if (data.status) {
                state.firstName  = user.firstName;
                state.lastName = user.lastName; 
                if (user.tipClient == 'pf') {
                    state.phone  = user.phone;
                    state.CNP = user.CNP;
                    state.city = user.city;
                    state.county = user.county;
                }
                
            }
        }
    }
});

export const {connectionError, reset, updateLoanProfile, update} = userSlice.actions;
export default userSlice.reducer;
